import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'

const Container = styled.aside`
  margin: 0 1rem 2rem;

  ${props => props.type === 'article' && css`
    max-width: 728px;
    margin: 0 auto 2rem;
  `}

  ${props => props.rectangle && css`
    max-height: 250px;
  `}
`;

const Inner = styled.ins`
  display: block;
`;

const Ads = ({ type, rectangle }) => {
  useEffect(() => {
    if (window) {
      window.adsbygoogle = window.adsbygoogle || []
      window.adsbygoogle.push({})
    }
  })

  return (
    <Container type={ type } rectangle={ rectangle }>
      { type === 'article' &&
        <Inner className="adsbygoogle"
          data-ad-layout="in-article"
          data-ad-format="fluid"
          data-ad-client="ca-pub-2181801692419175"
          data-ad-slot="7173765630" />
      }
      { type === 'link' &&
        <Inner className="adsbygoogle"
          data-ad-client="ca-pub-2181801692419175"
          data-ad-slot="5672053065"
          data-ad-format="link"
          data-full-width-responsive="true" />
      }
      { !type &&
        <Inner
          className="adsbygoogle"
          data-ad-client="ca-pub-2181801692419175"
          data-ad-slot="7127047067"
          data-ad-format="auto"
          data-full-width-responsive="true" />
      }
    </Container>
  )
}

Ads.propTypes = {
  type: PropTypes.string,
  rectangle: PropTypes.string,
}

export default Ads
